export enum LINK_TYPE {
  HUB = "HUB",
  WEB_PARTNERS = "WEB_PARTNERS",
  WEB_NEXICS = "WEB_NEXICS",
  WEB_NEXITERMS = "WEB_NEXITERMS",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  AUTH_FLAGS = "AUTH_FLAGS",
  AUTH_INFO = "AUTH_INFO",
  APP_HOME = "APP_HOME",
  APP_EXPLORE = "APP_EXPLORE",
  APP_SAVED = "APP_SAVED",
  APP_PARTNERS = "APP_PARTNERS",
  APP_CHECKOUT_OFFER = "APP_CHECKOUT_OFFER",
  APP_CHECKOUT_BUY = "APP_CHECKOUT_BUY",
  APP_EXPLORE_NFT_SHARE = "APP_EXPLORE_NFT_SHARE",
  APP_EXPLORE_NFT = "APP_EXPLORE_NFT",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  FORCLIENTS = "FORCLIENTS",
  FORHOTELS = "FORHOTELS",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  SUPPORT = "SUPPORT",
  PROFILE_SUPPORT = "PROFILE_SUPPORT",
  INNOVATIVE_STARTUP = "INNOVATIVE_STARTUP",
  TOS = "TOS",
  PRIVACY = "PRIVACY",
  COOCKIES = "COOCKIES",
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  X = "X",
  LINKEDIN = "LINKEDIN",
  REFERRAL = "REFERRAL",
  PRESSKIT = "PRESSKIT",
  MALLORCA = "MALLORCA",
  WALL = "WALL",
  PROFILE_WALLET = "PROFILE_WALLET",
  TAK = "TAK",
  PROFILE = "PROFILE",
  PROFILE_OFFERS = "PROFILE_OFFERS",
  FORPARTNERS = "FORPARTNERS",
  FOREXPERIENCE = "FOREXPERIENCE",
  FORGENERIC = "FORGENERIC",
  WALL_EXPERIENCE = "WALL_EXPERIENCE",
  MADRID = "MADRID",
  METASEARCH = "METASEARCH",
}

const LINK_CONFIG: {
  [key in LINK_TYPE]: string;
} = {
  HUB: "/hub",
  WALL: "/",
  PROFILE_WALLET: "/profile/wallet",
  FORCLIENTS: "/forclients",
  WALL_EXPERIENCE: "/experience",
  FORHOTELS: "/forhotels",
  FORPARTNERS: "/forpartners",
  FOREXPERIENCE: "/forexperience",
  FORGENERIC: "/forgeneric",
  WEB_PARTNERS: "/web/partners",
  WEB_NEXICS: "/nexics",
  WEB_NEXITERMS: "/nexiterms",
  APP_HOME: "/app",
  APP_EXPLORE: "/app/explore",

  APP_SAVED: "/app/saved",
  APP_PARTNERS: "/app/partners",
  APP_CHECKOUT_OFFER: "/app/actions/__NFTID__/checkout/offer",
  APP_CHECKOUT_BUY: "/app/actions/__NFTID__/checkout/buy",
  APP_EXPLORE_NFT_SHARE: "/app/explore/__NFTID__?shareBooking=true",
  APP_EXPLORE_NFT: "/app/explore/__NFTID__",

  TAK: "/tak/__NFTID__",
  PROFILE: "/profile",
  PROFILE_OFFERS: "/profile/offers",

  LOGIN: "/login",
  REGISTER: "/register",
  VERIFY_EMAIL: "/verify_email",
  AUTH_FLAGS: "/flags",
  FORGOT_PASSWORD: "/forgot_password",
  RESET_PASSWORD: "/?resetPasswordToken=__TOKEN__",
  AUTH_INFO: "/info",
  SUPPORT: "/support",
  PROFILE_SUPPORT: "/profile/support",
  INNOVATIVE_STARTUP: "/innovative_startup",
  TOS: "/tos",
  PRIVACY: "/privacy",
  COOCKIES: "/cookies",
  FACEBOOK: "https://www.facebook.com/people/Takyon/100077962911765/",
  INSTAGRAM: "https://www.instagram.com/takyon.io/",
  X: "",
  LINKEDIN: "https://www.linkedin.com/company/takyon1/",
  REFERRAL: "?referralcode=__CODE__",
  PRESSKIT: "/presskit",
  MALLORCA: "/mallorca",
  MADRID: "/madrid",
  METASEARCH: "/metasearch",
};

export default function getLink(
  type: LINK_TYPE,
  scope:
    | "DASHBOARD"
    | "API"
    | "EXCHANGE"
    | "EXCHANGE_FULL"
    | "CUSTOM" = "EXCHANGE",
  params?: any
) {
  let base = "";
  let link;
  let url = "";

  switch (scope) {
    case "API":
      link = LINK_CONFIG[type];
      base = String(process.env.REACT_APP_BACKEND_URI);
      url = base + link;
      break;
    case "DASHBOARD":
      link = LINK_CONFIG[type];
      base = String(process.env.REACT_APP_DASHBOARD_URI);
      url = base + link;
      break;
    case "EXCHANGE":
      link = LINK_CONFIG[type];
      url = link;
      break;
    case "EXCHANGE_FULL":
      link = LINK_CONFIG[type];
      base = String(process.env.REACT_APP_FRONTEND_URI);
      url = base + link;
      break;
    case "CUSTOM":
      if (params) url = params.link;

      break;
  }

  if (params)
    Object.keys(params).forEach((paramKey) => {
      url = url!.replaceAll(String(paramKey), params[paramKey]);
    });

  return url;
}
