import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getHotelInfo } from "../../../assets/Services/metasearch";
import Stars from "../../../assets/TakyonDesignSystem/components/Stars/Stars";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import iCollection from "../../../assets/models/iCollection";
import Skeleton from "react-loading-skeleton";

export default function MetasearchHotelPage() {
  const { id } = useParams();

  const [hotel, setHotel] = useState<{
    summary: any;
    info: any;
    details: any;
    reviews: any;
    reviewsSummary: any;
    collection?: iCollection;
  }>();

  const [isLoading, setIsLoading] = useState(false);

  const [images, setImages] = useState<string[]>([]);
  const [amenities, setAmenities] = useState<any[]>([]);

  const load = async () => {
    if (!id) return;

    setIsLoading(true);
    try {
      const data = await getHotelInfo({
        hotelsComId: id,
        locale: "it_IT",
        domain: "IT",
      });

      setHotel(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  // images
  useEffect(() => {
    if (!hotel) return;

    try {
      const images = hotel.summary.propertyGallery.images.map(
        (i: any) => i.image.url
      );

      setImages(images);
    } catch (error) {
      console.log(error);
    }
  }, [hotel]);

  // amenities
  useEffect(() => {
    if (!hotel) return;

    try {
      const amenities = hotel.summary.summary.amenities.topAmenities.items.map(
        (a: any) => a.icon.token
      );
      setAmenities(amenities);
    } catch (error) {
      console.log(error);
    }
  }, [hotel]);

  useEffect(() => {
    load();
  }, []);

  if (isLoading)
    return (
      <div className="bg-white">
        <br />
        <br />
        <br />
        <br />
        <div className="container">
          <div className="content">
            <Skeleton count={3} height={100} />
            <br />

            <Skeleton width={500} count={1} height={20} />
            <Skeleton width={800} count={3} height={10} />

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    );

  return (
    <div className="bg-white">
      <div className="container">
        <br />
        <br />
        <br />
        <br />

        <a href="/metasearch">{"< Back to search"}</a>

        <div className="d-flex bg-white" style={{ height: "400px" }}>
          <div className="col-4 h-100">
            <img
              className="p-1"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              src={images[0]}
              alt=""
            />
          </div>
          <div className="col-4 h-50">
            <img
              className="p-1"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              src={images[1]}
              alt=""
            />{" "}
            <img
              className="p-1"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              src={images[2]}
              alt=""
            />
          </div>
          <div className="col-4 h-50">
            <img
              className="p-1"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              src={images[3]}
              alt=""
            />{" "}
            <img
              className="p-1"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              src={images[4]}
              alt=""
            />
          </div>
        </div>

        <div className="d-flex justify-content-between pt-2">
          <div className="d-flex gap-3">
            <div
              className="pb-2 cursor-pointer"
              style={{ borderBottom: "4px solid green" }}
            >
              Panoramica
            </div>
            <div className="pb-2 cursor-pointer">Servizi</div>
            <div className="pb-2 cursor-pointer">Camere</div>
            <div className="pb-2 cursor-pointer">Accessibilità</div>
            <div className="pb-2 cursor-pointer">Regolamenti</div>
          </div>

          {hotel?.collection?.census.website ? (
            <Button
              onClick={() => {
                let url = hotel?.collection?.census.website;

                if (!url?.includes("http")) url = "https://" + url;

                window.open(url, "_blank");
              }}
              className="mb-1"
              text="Book on Hotel Website >"
            />
          ) : null}
        </div>

        <hr className="m-0" />

        <br />

        <div className="d-flex">
          <div className="col-6">
            <p className="dark-grey-color">
              {hotel?.summary.summary.location.address.addressLine}
            </p>
            <h1 className="h1">{hotel?.summary.summary.name}</h1>

            <Stars
              stars={hotel?.summary.summary.overview.propertyRating.rating}
            />
            <p className="bodytext-md">{hotel?.summary.summary.tagline}</p>

            <a href="">See all 123 reviews {">"}</a>

            <br />
            <br />
            <br />
            <p className="h3">Popular amenities</p>
            <div className="d-flex gap-3 mt-2">
              {amenities.map((a) => {
                return (
                  <div>
                    <IconNew icon={a} />
                  </div>
                );
              })}
            </div>

            <br />
            <br />
            <p className="h3">About the hotel</p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos
              quidem tempore veniam ex, est cumque modi voluptas facere
              dignissimos id veritatis quaerat quam temporibus vero, dolorem
              quas fugit inventore. Voluptate, inventore? Quas est voluptates
              impedit ipsa. Sed, ullam magni? Temporibus ratione, tempora
              consequatur dolore quia dolores laborum doloribus? Dolorem,
              voluptas!
            </p>
          </div>

          <div className="col-6 d-flex flex-column align-items-end">
            <div className="d-flex flex-column align-items-center gap-2">
              <p className="h3">Explore the area</p>

              <div className="border rounded">
                <img
                  style={{
                    height: "200px",
                    width: "350px",
                    objectFit: "cover",
                  }}
                  className="rounded border"
                  src={hotel?.summary.summary.location.staticImage.url}
                  alt=""
                />
                <div className="p-3">
                  <p className="dark-grey-color">
                    {hotel?.summary.summary.location.address.addressLine}
                  </p>
                  <a href="">View in map</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="text-center">... WEBSITE UNDER CONSTRUCTION ...</div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
