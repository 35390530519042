import { useRef, useState } from "react";
import {
  extractMainPropertyData,
  getCheckoutLink,
  HotelsComAmenities,
  HotelsComMealPlan,
  HotelsComRegion,
  HotelsComSearch,
  HotelsComSearchQueryParams,
  HotelsComSearchRegions,
  PropertyMainData,
} from "../../../assets/Services/metasearch";
import { debounce } from "lodash";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Skeleton from "react-loading-skeleton";
import Stars from "../../../assets/TakyonDesignSystem/components/Stars/Stars";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import logoSvgWhite from "../../../assets/TakyonDesignSystem/images/TakyonDark.svg";
import heroimg from "../../../assets/img/landing/heroimg.jpeg";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import Input from "../../../assets/TakyonDesignSystem/components/Input/Input";
import { iWallConfig, WALL_CONFIG } from "../../../config/wall";
import Wall from "../Wall/Wall";
import Switch from "../../../assets/TakyonDesignSystem/components/Switch/Switch";

export function RegionInput({
  onChange,
}: {
  onChange(params: { target: { name: string; value: string } }): void;
}) {
  const [query, setQuery] = useState(""); // Manage input value
  const [regionResults, setRegionResults] = useState<HotelsComRegion[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const search = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setIsLoading(true);

    const searchParams = {
      query: e.target.value,
      locale: "it_IT",
      domain: "IT",
    };

    try {
      const data = await HotelsComSearchRegions(searchParams);
      setRegionResults(data.data);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const searchCallbackDebounced = useRef(debounce(search, 1000));

  return (
    <div className="position-relative">
      {/* Region Results Dropdown */}
      {regionResults.length || isLoading ? (
        <div
          style={{ top: "120%", zIndex: "100", width: "150%" }}
          className="position-absolute bg-white border p-2 rounded"
        >
          {isLoading ? "loading..." : ""}
          {regionResults.map((region, key) => {
            return (
              <div
                key={region.gaiaId} // Add a unique key for list items
                onClick={() => {
                  // Update the query with the selected region's display name
                  setQuery(region.regionNames.displayName);

                  // Clear the dropdown
                  setRegionResults([]);

                  // Trigger onChange with the selected region's gaiaId
                  onChange({
                    target: { name: "region_id", value: region.gaiaId },
                  });
                }}
                className="my-2 cursor-pointer"
              >
                {region.regionNames.displayName}

                {key !== regionResults.length - 1 ? <hr /> : null}
              </div>
            );
          })}
        </div>
      ) : null}

      {/* Input Field */}
      <input
        style={{ border: "none" }}
        value={query}
        onChange={(e) => {
          // Only run this when the user types, not when clicking a region
          setQuery(e.target.value);
          setIsLoading(true);
          searchCallbackDebounced.current(e);
        }}
        placeholder="Where are you going?"
        id="region_id"
        name="region_id"
        className="form-control bg-transparent text-black"
        required
      />
    </div>
  );
}

export default function Metasearch() {
  const [result, setResult] = useState<PropertyMainData[]>();
  const [searchParams, setSearchParams] = useState<HotelsComSearchQueryParams>({
    region_id: 0,
    checkin_date: "",
    checkout_date: "",
    adults_number: 2,
    sort_order: "RECOMMENDED",
    locale: "it_IT",
    domain: "IT",
  });

  const menuCategories: iWallConfig = WALL_CONFIG.config;

  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const [isSearch, setIsSearch] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "checkin_date" || name === "checkout_date") {
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(value)) {
        alert("Invalid date format. Please use yyyy-MM-dd.");
        return;
      }
    }

    if (name === "children_ages") {
      setSearchParams((prev) => ({
        ...prev,
        [name]: value
          .split(",")
          .map((age) => age.trim())
          .join(","),
      }));
    } else {
      setSearchParams((prev) => ({
        ...prev,
        [name]:
          name === "region_id" ||
          name === "adults_number" ||
          name === "price_min" ||
          name === "price_max"
            ? +value
            : value,
      }));
    }
  };

  const search = async () => {
    setIsLoading(true);

    setResult(undefined);

    try {
      setIsSearch(true);
      const data = await HotelsComSearch(searchParams);

      setResult(data.map((p) => extractMainPropertyData(p)));
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  return (
    <div className="bg-black">
      <div className="">
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            borderBottom: "2px solid white",
            paddingBottom: isSearch ? "20px" : "100px",
            height: isSearch ? "170px" : "500px",
            width: "100vw",
            background: `url(${heroimg})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "relative",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              opacity: "0.8",
              top: 0,
              left: 0,
              zIndex: 1,
              position: "absolute",
            }}
            className="bg-black"
          ></div>

          <div
            style={{ zIndex: 2, position: "relative" }}
            className="container"
          >
            {!isSearch ? (
              <>
                <p className={`h1-large white-color medium text-center`}>
                  Skip the Middleman
                  <br />
                  <span className="primary-color">
                    Book Directly on Hotel Websites
                  </span>
                  <br />
                  Get Best Prices and Full Flexibility
                </p>

                <br />
                <br />
              </>
            ) : null}

            <div className="w-100">
              <form>
                <div className="d-flex gap-2">
                  <div
                    style={{ border: "2px solid white" }}
                    className="d-flex rounded w100"
                  >
                    <div className="w-100 bg-white-50">
                      <label
                        htmlFor="region_id"
                        className="form-label text-black px-2"
                      >
                        Location:
                      </label>
                      <RegionInput onChange={handleChange as any} />
                      <input
                        hidden
                        type="number"
                        id="region_id"
                        name="region_id"
                        className="form-control bg-transparent text-black"
                        value={searchParams.region_id || ""}
                        // onChange={handleChange}
                        required
                      />
                    </div>

                    <div
                      className="h100"
                      style={{ border: "1px solid white" }}
                    ></div>

                    <div className="d-flex w-100 bg-white-50">
                      <div className="w-100">
                        <label
                          htmlFor="checkin_date"
                          className="form-label text-black px-2"
                        >
                          Check-in Date:
                        </label>
                        <input
                          style={{ border: "none" }}
                          type="date"
                          id="checkin_date"
                          name="checkin_date"
                          className="form-control bg-transparent text-black"
                          value={searchParams.checkin_date || ""}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="w-100">
                        <label
                          htmlFor="checkout_date"
                          className="form-label text-black px-2"
                        >
                          Check-out Date:
                        </label>
                        <input
                          style={{ border: "none" }}
                          type="date"
                          id="checkout_date"
                          name="checkout_date"
                          className="form-control bg-transparent text-black"
                          value={searchParams.checkout_date || ""}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div
                      className="h100"
                      style={{ border: "1px solid white" }}
                    ></div>

                    <div className="d-flex w-100 bg-white-50">
                      <div className="w-100">
                        <label
                          htmlFor="adults_number"
                          className="form-label text-black px-2"
                        >
                          Number of Adults:
                        </label>
                        <input
                          style={{ border: "none" }}
                          type="number"
                          id="adults_number"
                          name="adults_number"
                          className="form-control bg-transparent text-black"
                          min="1"
                          value={searchParams.adults_number || ""}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="w-100">
                        <label
                          htmlFor="children_ages"
                          className="form-label text-black px-2"
                        >
                          Number of Children:
                        </label>
                        <input
                          style={{ border: "none" }}
                          type="text"
                          id="children_ages"
                          name="children_ages"
                          className="form-control bg-transparent text-black"
                          placeholder="i.e.: 4,7,0"
                          value={searchParams.children_ages || ""}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <div
                      onClick={() => !isLoading && search()}
                      className="bg-primary p-3"
                      style={{
                        height: "60px",
                        width: "60px",
                        aspectRatio: "1/1",
                        borderRadius: "100%",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!isLoading ? <IconNew icon="search" size={50} /> : "..."}
                    </div>

                    {/* <Button
                      text="Filters v"
                      variant="blank"
                      className="text-white border p-2 px-3"
                    /> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {isSearch ? (
          <div className="container">
            <div className="d-flex w-100 gap-4">
              <div
                style={{
                  width: "20%",
                  borderRight: "1px solid lightgray",
                  marginTop: "35px",
                  opacity: 0.8,
                }}
                className="rounded bg-white p-3"
              >
                <p className="bodytext-lg medium">Filter by</p>
                <br />

                <div className="d-flex justify-content-between align-items-center gap-3">
                  <p className="bodytext medium text-nowrap">
                    Certified by Takyon
                  </p>
                  <div style={{ transform: "scale(0.8)" }}>
                    <Switch />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center gap-3 mt-2">
                  <p className="bodytext medium text-nowrap">
                    Best Price on Hotel Website
                  </p>
                  <div style={{ transform: "scale(0.8)" }}>
                    <Switch />
                  </div>
                </div>

                <hr />

                <div>
                  <p className="bodytext medium mb-2">Price per night</p>
                  <div className="d-flex gap-3">
                    <div className="border rounded pt-1 px-2">
                      <label className="medium bodytext-sm" htmlFor="">
                        Min
                      </label>
                      <div className="d-flex align-items-center">
                        <p>€</p>
                        <input
                          style={{ border: "none" }}
                          name="price_min"
                          onChange={handleChange}
                          type="number"
                        />
                      </div>
                    </div>
                    <div className="border rounded pt-1 px-2">
                      <label className="medium bodytext-sm" htmlFor="">
                        Max
                      </label>
                      <div className="d-flex align-items-center">
                        <p>€</p>
                        <input
                          style={{ border: "none" }}
                          name="price_max"
                          onChange={handleChange}
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div>
                  <p className="bodytext medium mb-2">Amenities</p>

                  {Object.keys(HotelsComAmenities).map((amenity, key) => {
                    return (
                      <div
                        className="d-flex align-items-center gap-3"
                        key={key}
                      >
                        <div>
                          <input
                            onChange={(e) => {
                              if (e.target.checked)
                                setSearchParams({
                                  ...searchParams,
                                  amenities: (searchParams.amenities =
                                    (searchParams.amenities ?? "") +
                                    `${amenity},`),
                                });
                              else {
                                setSearchParams({
                                  ...searchParams,
                                  amenities: searchParams.amenities?.replaceAll(
                                    `${amenity},`,
                                    ""
                                  ),
                                });
                              }
                            }}
                            type="checkbox"
                          />
                        </div>
                        <label
                          className="bodytext-sm"
                          style={{ textTransform: "capitalize" }}
                          htmlFor={amenity}
                        >
                          {amenity.toLowerCase().replaceAll("_", " ")}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <hr />
                <div>
                  <p className="bodytext medium mb-2">Meal Plan</p>

                  {Object.keys(HotelsComMealPlan).map((plan, key) => {
                    return (
                      <div
                        className="d-flex align-items-center gap-3"
                        key={key}
                      >
                        <div>
                          <input
                            onChange={(e) => {
                              if (e.target.checked)
                                setSearchParams({
                                  ...searchParams,
                                  meal_plan: (searchParams.meal_plan =
                                    (searchParams.meal_plan ?? "") +
                                    `${plan},`),
                                });
                              else {
                                setSearchParams({
                                  ...searchParams,
                                  meal_plan: searchParams.meal_plan?.replaceAll(
                                    `${plan},`,
                                    ""
                                  ),
                                });
                              }
                            }}
                            type="checkbox"
                          />
                        </div>

                        <label
                          className="bodytext-sm"
                          style={{ textTransform: "capitalize" }}
                          htmlFor={plan}
                        >
                          {plan.toLowerCase().replaceAll("_", " ")}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <br />

                <div className="d-flex justify-content-center">
                  <Button
                    variant="secondary"
                    size="sm"
                    text="Show more filters v"
                  />
                </div>
              </div>

              <div style={{ width: "80%" }}>
                {isLoading ? (
                  <div
                    style={{
                      marginTop: "35px",
                    }}
                  >
                    <Skeleton height={200} count={5} />
                  </div>
                ) : null}

                {result && (
                  <div className="">
                    <p className="bodytext text-white my-2">
                      More than {result.length} Results found:
                    </p>
                    <div className="d-flex flex-column gap-3">
                      {result.map((property, index) => (
                        <div className="" key={index}>
                          <div
                            style={{
                              border: property._collection
                                ? "2px solid #8ce786"
                                : "",
                              boxShadow: property._collection
                                ? "0px 0px 10px #8ce786"
                                : "0px 0px 10px lightgray",

                              background: property._collection
                                ? // ? "rgb(134,188,120)"
                                  "rgb(231,231,231)"
                                : "rgb(231,231,231)",
                            }}
                            className={`card d-flex flex-row h-100`}
                            onClick={() => {
                              // window.open("/metasearch/" + property.id);
                              // if (!property._collection) return;
                              // navigateToHotelWebsite({
                              //   collectionId: property._collection?._id,
                              //   checkin_date: searchParams.checkin_date,
                              //   checkout_date: searchParams.checkout_date,
                              //   adults_number: searchParams.adults_number ?? 1,
                              // });
                            }}
                          >
                            <div className="p-2">
                              <img
                                className="rounded"
                                src={property.propertyImage}
                                alt={"Hotel"}
                                style={{
                                  width: "200px",
                                  height: "180px",
                                  objectFit: "cover",
                                  border: "1px solid white",
                                }}
                              />
                            </div>
                            <div className="card-body d-flex w100">
                              <div className="d-flex flex-column w100">
                                <div className="d-flex gap-2">
                                  <h5
                                    style={{ margin: 0, maxWidth: "300px" }}
                                    className="card-title bodytext-lg text-ellipsis"
                                  >
                                    {property.name}
                                  </h5>
                                  <Stars
                                    className="black-color"
                                    stars={property.starRating}
                                  />
                                </div>

                                <div className="mt-2">
                                  <p className="card-text">
                                    <span className="bg-black p-1 px-2 rounded text-white mx-1">
                                      {property.rating.score}
                                    </span>
                                    <span className="bodytext-sm">
                                      ({property.rating.totalReviews} reviews)
                                    </span>
                                  </p>
                                </div>

                                <p className="bodytext light mt-3 light-black-color">
                                  {property.location}
                                </p>

                                {property._collection ? (
                                  <div
                                    className="bg-black- p-2 mt-auto d-flex- justify-content-center"
                                    style={{
                                      // width: "180px",
                                      width: "100%",
                                      borderRadius: "100px",
                                    }}
                                  >
                                    <div className="d-flex align-items-center gap-1">
                                      <p className="text-white- bodytext-sm medium">
                                        <u>
                                          Best conditions & Resellability by
                                        </u>
                                      </p>
                                      <img
                                        src={logoSvgWhite}
                                        alt=""
                                        style={{ height: "17px" }}
                                      />
                                    </div>
                                    {/* <div>
                                      <p className="text-white bodytext-sm">
                                        # BEST CONDITIONS
                                      </p>
                                      <p className="text-white bodytext-sm">
                                        # RESELLEBILITY
                                      </p>
                                      <p className="text-white bodytext-sm">
                                        # TAKYON SUPPORT
                                      </p>
                                    </div> */}
                                  </div>
                                ) : null}
                              </div>

                              <div className="d-flex flex-column align-items-end justify-content-end w100 gap-4">
                                <div className="d-flex gap-3 flex-column">
                                  <div
                                    style={{
                                      position: "relative",
                                      // border: property._collection
                                      //   ? "2px solid black"
                                      //   : "2px solid black",
                                      // : "1px solid white",
                                    }}
                                    className="bg-white h100 rounded d-flex p-2 pt-3 justify-content-between flex-column "
                                  >
                                    <div>
                                      <p className="bodytext medium mb-2">
                                        {property._collection?.census.website
                                          ?.replace("www.", "")
                                          .replace("https://", "")
                                          .replace("/", "")
                                          .replace("http:/", "")
                                          .replace("http://", "")}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center gap-5">
                                      <p className="h3">
                                        € {(property.price * 0.9).toFixed(2)}
                                      </p>

                                      <div className="d-flex flex-column align-items-center justify-content-center position-relative">
                                        <Button
                                          onClick={() => {
                                            let url =
                                              property?._collection?.census
                                                .website;

                                            if (!url?.includes("http"))
                                              url = "https://" + url;

                                            window.open(url, "_blank");
                                          }}
                                          className="mb-1"
                                          text="Visit Hotel website >"
                                          style={{ zIndex: 2 }}
                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "-25px",
                                            right: "0",
                                            zIndex: 1,
                                          }}
                                          className="bg-black text-white p-1 pb-3 px-3 bodytext-sm"
                                        >
                                          Save{" "}
                                          {(property.price * 0.09).toFixed(0)}€
                                        </div>
                                        {/* <p className="bodytext-sm">
                                          Save{" "}
                                          {(property.price * 0.09).toFixed(0)}€
                                        </p> */}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex gap-2 w100">
                                    <div className="bg-light-grey rounded border p-1 px-3 flex-column w100">
                                      <p className="bodytext-sm">Hotels.com</p>
                                      <p className="bodytext medium">
                                        € {property.price}{" "}
                                        {/* <span className="text-red bodytext-sm">
                                          (+ €
                                          {(property.price * 0.09).toFixed(0)})
                                        </span> */}
                                      </p>
                                    </div>
                                    <div className="bg-light-grey rounded border p-1 px-3 flex-column w100">
                                      <p className="bodytext-sm">Booking.com</p>
                                      <p className="bodytext medium">
                                        € {(property.price * 0.99).toFixed(2)}{" "}
                                        {/* <span className="text-red bodytext-sm">
                                          (+ €
                                          {(property.price * 0.09).toFixed(0)})
                                        </span> */}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}

        {isSearch ? (
          <></>
        ) : (
          <div style={{ marginTop: "-50px" }}>
            <Wall
              hideHero
              menuCategories={menuCategories}
              category={menuCategories.find((cat) => cat.area === "all")!.slots}
            />
          </div>
        )}
      </div>
    </div>
  );
}
