import axios from "axios";
import iCollection from "../models/iCollection";

export enum HotelsComAccessibility {
  SIGN_LANGUAGE_INTERPRETER = "SIGN_LANGUAGE_INTERPRETER",
  STAIR_FREE_PATH = "STAIR_FREE_PATH",
  SERVICE_ANIMAL = "SERVICE_ANIMAL",
  IN_ROOM_ACCESSIBLE = "IN_ROOM_ACCESSIBLE",
  ROLL_IN_SHOWER = "ROLL_IN_SHOWER",
  ACCESSIBLE_BATHROOM = "ACCESSIBLE_BATHROOM",
  ELEVATOR = "ELEVATOR",
  ACCESSIBLE_PARKING = "ACCESSIBLE_PARKING",
}

export enum HotelsComAmenities {
  SPA_ON_SITE = "SPA_ON_SITE",
  WIFI = "WIFI",
  HOT_TUB = "HOT_TUB",
  FREE_AIRPORT_TRANSPORTATION = "FREE_AIRPORT_TRANSPORTATION",
  POOL = "POOL",
  GYM = "GYM",
  OCEAN_VIEW = "OCEAN_VIEW",
  WATER_PARK = "WATER_PARK",
  BALCONY_OR_TERRACE = "BALCONY_OR_TERRACE",
  KITCHEN_KITCHENETTE = "KITCHEN_KITCHENETTE",
  ELECTRIC_CAR = "ELECTRIC_CAR",
  PARKING = "PARKING",
  CRIB = "CRIB",
  RESTAURANT_IN_HOTEL = "RESTAURANT_IN_HOTEL",
  PETS = "PETS",
  WASHER_DRYER = "WASHER_DRYER",
  CASINO = "CASINO",
  AIR_CONDITIONING = "AIR_CONDITIONING",
}

export enum HotelsComMealPlan {
  ALL_INCLUSIVE = "ALL_INCLUSIVE",
  FULL_BOARD = "FULL_BOARD",
  HALF_BOARD = "HALF_BOARD",
  FREE_BREAKFAST = "FREE_BREAKFAST",
}

export enum HotelsComAvailableFilter {
  SHOW_AVAILABLE_ONLY = "SHOW_AVAILABLE_ONLY",
}

export enum HotelsComPaymentType {
  GIFT_CARD = "GIFT_CARD",
  PAY_LATER = "PAY_LATER",
  FREE_CANCELLATION = "FREE_CANCELLATION",
}

export enum HotelsComLodgingType {
  HOSTAL = "HOSTAL",
  APARTMENT = "APARTMENT",
  APART_HOTEL = "APART_HOTEL",
  CHALET = "CHALET",
  HOTEL = "HOTEL",
  RYOKAN = "RYOKAN",
  BED_AND_BREAKFAST = "BED_AND_BREAKFAST",
  HOSTEL = "HOSTEL",
}

export interface HotelsComSearchQueryParams {
  accessibility?: string; // Accessibility options
  amenities?: string; // Amenities options
  meal_plan?: string; // Meal plan options
  available_filter?: HotelsComAvailableFilter; // Available filter
  price_min?: number; // Minimum price
  payment_type?: string; // Payment types
  star_rating_ids?: string; // Hotel star ratings (as strings to allow flexibility)
  guest_rating_min?: number; // Minimum guest rating
  children_ages?: string; // Comma-separated ages of children
  checkin_date: string; // Check-in date (required)
  locale: string; // Locale (enum can be used if values are known)
  adults_number: number; // Number of adults (required)
  sort_order: string; // Sort order (enum can be used if values are known)
  page_number?: number; // Page number
  domain: string; // Domain (enum can be used if values are known)
  price_max?: number; // Maximum price
  region_id: number; // Region ID (required)
  lodging_type?: string; // Lodging types
  checkout_date: string; // Checkout date (required)
}

interface Property {
  __typename: "Property";
  id: string;
  featuredMessages: any[];
  name: string;
  availability: {
    __typename: "PropertyAvailability";
    available: boolean;
    minRoomsLeft: number;
  };
  propertyImage: {
    __typename: "PropertyImage";
    alt: string;
    fallbackImage: string | null;
    image: {
      __typename: "Image";
      description: string;
      url: string;
    };
    subjectId: number;
  };
  destinationInfo: {
    __typename: "PropertyDestinationInfo";
    distanceFromDestination: {
      __typename: "Distance";
      unit: string;
      value: number;
    };
    distanceFromMessaging: any | null;
    regionId: string;
  };
  legalDisclaimer: string | null;
  listingFooter: string | null;
  mapMarker: {
    __typename: "MapMarker";
    label: string;
    latLong: {
      __typename: "Coordinates";
      latitude: number;
      longitude: number;
    };
  };
  neighborhood: {
    __typename: "Region";
    name: string;
  };
  offerBadge: string | null;
  offerSummary: {
    __typename: "OfferSummary";
    messages: Array<{
      __typename: "OfferSummaryMessage";
      message: string;
      theme: string | null;
      type: string;
      mark: {
        __typename: "Mark";
        id: string;
        token: string;
        description: string;
      };
    }>;
    attributes: any[];
  };
  pinnedDetails: any | null;
  price: PropertyPrice;
  priceAfterLoyaltyPointsApplied: PropertyPrice;
  propertyFees: Array<{
    __typename: "Fees";
    type: string;
  }>;
  reviews: {
    __typename: "PropertyReviewsSummary";
    score: number;
    total: number;
  };
  star: number;
  supportingMessages: any | null;
  regionId: string;
  priceMetadata: {
    __typename: "PropertyPriceMetadata";
    discountType: string;
    rateDiscount: {
      __typename: "RateDiscount";
      description: string;
    };
    totalDiscountPercentage: number;
  };
  saveTripItem: any | null;

  _collection?: iCollection;
}

interface PropertyPrice {
  __typename: "PropertyPrice";
  options: Array<{
    __typename: "PropertyPriceOption";
    strikeOut: Money | null;
    disclaimer: {
      __typename: "LodgingPlainMessage";
      value: string;
    };
    formattedDisplayPrice: string;
  }>;
  priceMessaging: any | null;
  lead: Money;
  strikeOut: Money | null;
  displayMessages: Array<{
    __typename: "PriceDisplayMessage";
    lineItems: Array<
      | {
          __typename: "DisplayPrice";
          disclaimer: {
            __typename: "LodgingPlainDialog";
            content: string[];
            title: string | null;
          } | null;
          price: {
            __typename: "FormattedMoney";
            formatted: string;
            accessibilityLabel: string;
          };
          role: string;
        }
      | {
          __typename: "LodgingEnrichedMessage";
          accessibilityLabel: string | null;
          mark: any | null;
          state: string;
          value: string;
          badge: any | null;
        }
    >;
  }>;
  strikeOutType: string;
  priceMessages: Array<{
    __typename: "LodgingPlainMessage";
    value: string;
  }>;
}

interface Money {
  __typename: "Money";
  amount: number;
  currencyInfo: {
    __typename: "Currency";
    code: string;
    symbol: string;
  };
  formatted: string;
}

export type PropertyMainData = {
  name: string;
  id: string;
  propertyImage: string;
  starRating: number;
  location: string;
  highlight: string | null;
  features: string[];
  // description: string;
  price: number;
  priceFormatted: string;
  rating: {
    score: number;
    totalReviews: number;
  };
  _collection?: iCollection;
};

export function extractMainPropertyData(property: Property): PropertyMainData {
  return {
    id: property.id,
    propertyImage: property.propertyImage.image.url,
    name: property.name,
    starRating: property.star || 0,
    location: property.neighborhood?.name || "",
    highlight: property.offerBadge || null,
    features: property.offerSummary?.attributes || [],
    // description: property.featuredMessages?.[0] || "",
    priceFormatted: property.price?.lead?.formatted || "",
    price: property.price?.lead?.amount,
    rating: {
      score: property.reviews?.score || 0,
      totalReviews: property.reviews?.total || 0,
    },
    _collection: property._collection,
  };
}

export interface HotelsComSearchRegions {
  query: string;
  locale: string;
  domain: string;
}

export interface HotelsComRegion {
  gaiaId: string;
  regionNames: {
    displayName: string;
    fullName: string;
    lastSearchName: string;
    primaryDisplayName: string;
    secondaryDisplayName: string;
    shortName: string;
  };
}

export async function HotelsComSearch(
  params: HotelsComSearchQueryParams
): Promise<Property[]> {
  const res = await axios.post("/metasearch/hotels", params);
  return res.data;
}
export async function HotelsComSearchRegions(
  params: HotelsComSearchRegions
): Promise<{
  data: HotelsComRegion[];
}> {
  const res = await axios.post("/metasearch/regions", params);
  return res.data;
}
export async function getCheckoutLink(params: {
  collectionId: string;
  checkIn: string;
  checkOut: string;
  guestsA: number;
}): Promise<string> {
  const res = await axios.post("/metasearch/checkout", params);
  return res.data;
}

export async function getHotelInfo(params: {
  hotelsComId: string;
  domain: string;
  locale: string;
}): Promise<{
  summary: any;
  info: any;
  details: any;
  reviews: any;
  reviewsSummary: any;
  collection?: iCollection;
}> {
  const res = await axios.get("/metasearch/hotel/" + params.hotelsComId, {
    params,
  });
  return res.data;
}
