import { Route, Routes, useLocation } from "react-router-dom";

import Forclients from "./pages/ForClients/ForClients";
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";
import NotFound from "../_Shared/pages/NotFound/NotFound";
import UnsubscribeMarketing from "../_Shared/pages/UnsubscribeMarketing/UnsubscribeMarketing";
import ForHotels from "./pages/ForHotels/ForHotels";
import { Documents } from "./pages/Documents/Documents";
import Support from "./pages/Support/Support";
import Partners from "./pages/Partners/Partners";
import { useEffect } from "react";
import { toggleScrolling } from "../assets/Utils/generic";
import getLink, { LINK_TYPE } from "../config/Links";
import Hub, { HubLoader } from "./pages/Hub/Hub";
import Wall from "./pages/Wall/Wall";
import BHWForclients from "./pages/ForHotels/BWHForClients";
import NexiCs from "./pages/Nexi/NexiCs";
import NexiTerms from "./pages/Nexi/Nexiterms";
import { InnovativeStartup } from "./pages/InnovativeStartup/InnovativeStartup";
import LandingMallorca from "./pages/LandingMadrid/LandingMadrid";
import { ProfileRoutes } from "./pages/Profile/ProfileRoutes";
import { Retrieve } from "./components/Auth/AuthComponents";
import UbPromoLanding from "./pages/Ubox/UbPromoLanding";
import { useTranslation } from "react-i18next";
import { iWallConfig, WALL_CONFIG } from "../config/wall";
import Stays from "./pages/Stays/Stays";
import RequiresAuth from "./hoc/RequiresAuth";
import ForPartners from "./pages/ForPartners/ForPartners";
import ForExperience from "./pages/ForExperience/ForExperience";
import ForGeneric from "./pages/ForGeneric/ForGeneric";
import openAuthModal, { AUTH_SECTION } from "./components/Auth/Auth";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";
import LandingMadrid from "./pages/LandingMadrid/LandingMadrid";
import CatMenu from "./components/CatMenu/CatMenu";
import { fireTagManagerEvent } from "../assets/Services/tagmanager";
import Metasearch from "./pages/Metasearch/Metasearch";
import MetasearchHotelPage from "./pages/Metasearch/MetasearchHotelPage";

export const PROMO_AUTH_KEY =
  "vwb7n58796mm6n86b745v" + process.env.REACT_APP_FRONTEND_URI;

function scrollToTop() {
  window.scrollTo({ top: 0 });
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
    if (pathname !== getLink(LINK_TYPE.FORCLIENTS)) toggleScrolling(true);
    setTimeout(() => {
      scrollToTop();
    }, 5);
  }, [pathname]);

  return <></>;
}

function WebRouter({ menuCategories }: { menuCategories: iWallConfig }) {
  return (
    <>
      <Routes>
        <Route path="/metasearch" element={<Metasearch />} />
        <Route path="/metasearch/:id" element={<MetasearchHotelPage />} />
        <Route path="/retrieve" element={<Retrieve />} />
        <Route
          path="/profile/*"
          element={
            <RequiresAuth>
              <ProfileRoutes />
            </RequiresAuth>
          }
        />
        <Route path="/hub" element={<Hub />} />

        <Route path="/forpartners" element={<ForPartners />} />
        <Route path="/forhotels" element={<ForHotels />} />
        <Route path="/forhotelsv2" element={<ForHotels />} />
        <Route path="/forexperience" element={<ForExperience />} />
        <Route path="/forgeneric" element={<ForGeneric />} />

        <Route path="/forclients" element={<Forclients />} />

        <Route path="/bwh" element={<BHWForclients />} />
        <Route path="/innovative_startup" element={<InnovativeStartup />} />
        <Route path="/tos" element={<Documents name="Terms of Service" />} />
        <Route
          path="/terms-conditions"
          element={<Documents name="Terms of Service" />}
        />
        <Route path="/privacy" element={<Documents name="Privacy Policy" />} />
        <Route path="/cookies" element={<Documents name="Cookie Policy" />} />
        <Route path="support" element={<Support />} />
        <Route path="/web/partners" element={<Partners />} />
        <Route path="/unsubscribe" element={<UnsubscribeMarketing />} />
        <Route path="/nexics" element={<NexiCs />} />
        <Route path="/nexiterms" element={<NexiTerms />} />
        <Route path="/madrid" element={<LandingMadrid />} />
        <Route path="/university-box" element={<UbPromoLanding />} />
        <Route path="/stays" element={<Stays />} />
        <Route path="*" element={<NotFound />} />
        {menuCategories && menuCategories.length > 0 ? (
          <Route
            path="/"
            element={
              <>
                <CatMenu menuCategories={menuCategories}></CatMenu>
                <Wall
                  menuCategories={menuCategories}
                  category={menuCategories[0].slots}
                />
              </>
            }
          />
        ) : null}
        {menuCategories && menuCategories.length > 0
          ? menuCategories
              .filter((category: any) => category.area !== "all")
              .map((category: any, index: number) => (
                <Route
                  key={index}
                  path={`/${category.area}`}
                  element={
                    <>
                      <CatMenu menuCategories={menuCategories}></CatMenu>
                      <Wall
                        key={`wall_${index}`}
                        menuCategories={menuCategories}
                        category={category.slots}
                      />
                    </>
                  }
                />
              ))
          : null}
      </Routes>
    </>
  );
}

export default function Web() {
  const { pathname } = useLocation();
  const menuCategories: iWallConfig = WALL_CONFIG.config;

  const user = useSelector(selectUser);

  useEffect(() => {
    const handleScroll = () => {
      window.removeEventListener("scroll", handleScroll);
      setTimerScroll();
    };

    const setTimerScroll = () => {
      window.setTimeout(() => {
        fireTagManagerEvent("scroll_land");
      }, 5000);
    };

    window.addEventListener("scroll", handleScroll);

    if (user || localStorage.getItem(PROMO_AUTH_KEY)) return;

    setTimeout(() => {
      openAuthModal(AUTH_SECTION.register_promo);

      localStorage.setItem(PROMO_AUTH_KEY, "true");
    }, 60 * 1000);
  }, []);

  return (
    <>
      {!pathname.includes(getLink(LINK_TYPE.MADRID))}
      <div
        className={`${
          pathname.includes(getLink(LINK_TYPE.PROFILE))
            ? "white-background"
            : "black-background"
        } h100 w100`}
      >
        <Nav />
        <div
          style={{
            height: pathname.includes(getLink(LINK_TYPE.PROFILE))
              ? "calc(100vh - 75px)"
              : "",
          }}
        >
          <WebRouter menuCategories={menuCategories} />
          <ScrollToTop />
        </div>
        {!pathname.includes(getLink(LINK_TYPE.PROFILE)) &&
          !pathname.includes(getLink(LINK_TYPE.WEB_PARTNERS)) && <Footer />}
      </div>
    </>
  );
}
